import { defineMessages } from 'react-intl'

export default defineMessages({
  textTitle: {
    id: 'Profile.text.title',
    defaultMessage: 'Sign up'
  },
  labelEmail: {
    id: 'Profile.label.email',
    defaultMessage: 'Email'
  },
  labelPhone: {
    id: 'Profile.label.telephone_number',
    defaultMessage: 'Phone number'
  },
  labelPassword: {
    id: 'Profile.label.password',
    defaultMessage: 'Password'
  },
  labelFirstName: {
    id: 'Profile.label.first_name',
    defaultMessage: 'First name'
  },
  labelLastName: {
    id: 'Profile.label.last_name',
    defaultMessage: 'Last name'
  },
  labelLanguage: {
    id: 'Profile.label.language',
    defaultMessage: 'Preferred language'
  },
  labelProperties: {
    id: 'Profile.label.properties',
    defaultMessage: 'Properties'
  },
  labelAddProperty: {
    id: 'Profile.label.addProperty',
    defaultMessage: 'Add property'
  },
  errorRequired: {
    id: 'Profile.error.required',
    defaultMessage: '{input} is required'
  },
  errorInvalid: {
    id: 'Profile.error.invalid',
    defaultMessage: 'Invalid {input}'
  },
  errorTooShort: {
    id: 'Profile.error.too_short',
    defaultMessage: '{input} must contain at least {min} characters'
  },
  textPrivacyNotice: {
    id: 'Profile.text.privacy_notice',
    defaultMessage: 'Read our <a>privacy notice</a> about how we process your personal data.'
  },
  toastUpdateSuccess: {
    id: 'Profile.toast.update_success',
    defaultMessage: 'User profile updated'
  },
  toastAddPropertySuccess: {
    id: 'Profile.toast.add_property_success',
    defaultMessage: 'Property {property} added'
  },
  toastRemovePropertySuccess: {
    id: 'Profile.toast.remove_property_success',
    defaultMessage: 'Property {property} removed'
  },
  buttonCancel: {
    id: 'Profile.button.cancel',
    defaultMessage: 'Cancel'
  },
  buttonSave: {
    id: 'Profile.button.save',
    defaultMessage: 'Save'
  },
  errorGeneral: {
    id: 'Profile.error.general',
    defaultMessage: 'Something went wrong when fetching data.'
  },
  errorUserExists: {
    id: 'Profile.error.user.exists',
    defaultMessage: 'You already have a Husqvarna ID. Use your email address to sign in.'
  },
});
