import AppConfig from 'appConfig';
import Const from 'domain/constants';

const BASE_URL = AppConfig.api.endpoint;

const errorMessage = (status: number) => {
  switch (status) {
    case 401:
      return  Const.ERROR_TYPE.UNAUTHORIZED;
    case 403:
      return Const.ERROR_TYPE.FORBIDDEN;
    default:
      return Const.ERROR_TYPE.BAD_REQUEST;
  }
};

export const getUser = async () => {
  const response = await fetch(`${BASE_URL}/api/0.1/user`, {
    credentials: 'include'
  });
  if (!response.ok) {
    throw new Error(errorMessage(response.status));
  }
  return response.json();
};

export const updateUser = async (userAttributes: any) => {
  const response = await fetch(`${BASE_URL}/api/0.1/user`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      attributes: userAttributes
    })
  });
  if (!response.ok) {
    throw new Error(errorMessage(response.status));
  }
  return response.json();
};

export const getProperties = async () => {
  const response = await fetch(`${BASE_URL}/api/0.1/user/properties`, {
    credentials: 'include'
  });
  if (!response.ok) {
    throw new Error(errorMessage(response.status));
  }
  return response.json();
};

export const saveProperties = async (properties: string[]) => {
  const response = await fetch(`${BASE_URL}/api/0.1/user/properties`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      propertyNames: properties
    })
  });
  if (!response.ok) {
    throw new Error(errorMessage(response.status));
  }
  return response.json();
};

export const removeProperties = async (properties: string[]) => {
  const response = await fetch(`${BASE_URL}/api/0.1/user/properties`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      propertyNames: properties
    })
  });
  if (!response.ok) {
    throw new Error(errorMessage(response.status));
  }
  return response.json();
};

export const signOutUser = async () => {
  const response = await fetch(`${BASE_URL}/api/0.1/user/logout`, {
    credentials: 'include'
  });
  if (!response.ok) {
    throw new Error(errorMessage(response.status));
  }
  return response.json();
};

export const getArcGisToken = async () => {
  const response = await fetch(`${BASE_URL}/api/0.1/arcgis/token`, {
    credentials: 'include'
  });
  if (!response.ok) {
    throw new Error(errorMessage(response.status));
  }
  return response.json();
};
