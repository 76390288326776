import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from './queries';
import Const from 'domain/constants';
import ProtectedLayout from './ProtectedLayout';
import Spinner from './Spinner';

type Props = {
  children?: React.ReactElement;
};

export default function ProtectedRoute({ children }: Props): React.ReactElement {
  const navigate = useNavigate();

  const auth = useAuth({
    onError: (error: string) => {
      if (error === Const.ERROR_TYPE.UNAUTHRIZED) {
        navigate('/signin');
      } else if (error === Const.ERROR_TYPE.FORBIDDEN) {
        navigate('/404')
      } else {
        navigate('/signin');
      }
    }
  });

  if (auth.isSuccess) {
    return children ? children : (
      <ProtectedLayout>
        <Outlet />
      </ProtectedLayout>
    );
  }

  return (
    <div className="w-full h-full flex">
      <Spinner size="large" />
    </div>
  );
}
