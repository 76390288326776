import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl, createIntl } from 'react-intl';
import { UserAttributes, CreateData } from 'domain/types';
import { useDiamLanguages } from 'api/diam/queries';
import Button from 'components/form/Button';
import Input from 'components/form/Input';
import Select, { toSelectable } from 'components/form/Select';
import messages from './messages';
import * as locales from 'domain/locales';

type Props = {
  createUser: {
    mutate: Function;
  };
};

export default function SignUpForm({ createUser }: Props) {
  const navigate = useNavigate();
  const intl = useIntl();
  const { data, isLoading } = useDiamLanguages();

  const { register, handleSubmit, formState: { errors } } = useForm<UserAttributes>();

  const languages = data?.data
    ?.map(({ id, attributes:{ name } }) => ([id, name]))
    .map(toSelectable)
    .filter(c => Object.values<string>(locales.Locale).includes(c.value))
    ?? [];

  const onSubmit: SubmitHandler<UserAttributes> = (formData: UserAttributes) => {
    const tempIntl = createIntl({
      locale: formData["language"] || locales.Locale.English,
      messages: locales.messages.get(
        formData["language"] || locales.Locale.English
      ),
    });

    const data: CreateData = {
      formData: formData,
      metaText: tempIntl.formatMessage(messages.textSignUpEmail),
    };
    createUser.mutate(data);
  };

  const handleCancel = () => navigate('/signin');

  return (
    <>
      <h2>
        <FormattedMessage {...messages.textTitle} />
      </h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex columns-2 gap-sm">
          <Input
            name="email"
            label={intl.formatMessage(messages.labelEmail)}
            error={errors.username?.message}
            register={register('username', {
              required: intl.formatMessage(messages.errorRequired, { input: intl.formatMessage(messages.labelEmail) }),
              pattern: {
                value: /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
                message: intl.formatMessage(messages.errorInvalid, { input: intl.formatMessage(messages.labelEmail) })
              }
            })}
          />
          <Input
            name="password"
            label={intl.formatMessage(messages.labelPassword)}
            type="password"
            error={errors.password?.message}
            register={register('password', {
              required: intl.formatMessage(messages.errorRequired, { input: intl.formatMessage(messages.labelPassword) }),
              minLength: {
                value: 6,
                message: intl.formatMessage(messages.errorTooShort, {
                  input: intl.formatMessage(messages.labelPassword),
                  min: 6
                })
              }
            })}
          />
        </div>
        <div className="flex columns-2 gap-sm">
          <Input
            name="first_name"
            label={intl.formatMessage(messages.labelFirstName)}
            error={errors.first_name?.message}
            register={register('first_name', {
              required: intl.formatMessage(messages.errorRequired, { input: intl.formatMessage(messages.labelFirstName) })
            })}
          />
          <Input
            name="last_name"
            label={intl.formatMessage(messages.labelLastName)}
            error={errors.last_name?.message}
            register={register('last_name', {
              required: intl.formatMessage(messages.errorRequired, { input: intl.formatMessage(messages.labelLastName) })
            })}
          />
        </div>
        <div className="flex">
          <Select
            name="language"
            label={intl.formatMessage(messages.labelLanguage)}
            options={languages}
            disabled={isLoading}
            error={errors.language?.message}
            register={register('language', {
              required: intl.formatMessage(messages.errorRequired, { input: intl.formatMessage(messages.labelLanguage) })
            })}
          />
        </div>

        <p>
          <FormattedMessage
            {...messages.textPrivacyNotice}
            values={{
              a: chunks => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://privacyportal.husqvarnagroup.com/${intl.locale === 'sv' ? 'se' : 'int'}/`}
                >
                  {chunks}
                </a>
              )
            }}
          />
        </p>

        <div className="flex columns-2 gap-sm pt-base">
          <div className="w-full">
            <Button state="transparent" onClick={handleCancel}>
              <FormattedMessage {...messages.buttonCancel} />
            </Button>
          </div>
          <div className="w-full">
            <Button type="submit">
              <FormattedMessage {...messages.buttonSignUp} />
            </Button>
          </div>
        </div>
      </form>

    </>
  );
}
