import { useQuery, useQueryClient } from 'react-query';
import { signOutUser } from 'api/intellion/intellionApi';
import { authKeys } from 'components/queryKeyFactory';

export const useSignOut = () => useQuery(authKeys.signOut(), signOutUser, {
  enabled: false,
  onSuccess: () => {
    localStorage.removeItem('state');
    const queryClient = useQueryClient();
    queryClient.invalidateQueries(authKeys.all);
  }
});
