type Props = React.PropsWithChildren<{}>;

export default function MainLayout({ children }: Props) {
  return (
    <div className="flex flex-row p-base w-full">
      <div className="desktop:large:basis-2/12 laptop:basis-1/12 mobile:tablet:basis-0" />
      <div className="dekstop:large:basis-8/12 laptop:basis-10/12 mobile:tablet:basis-1 text-base leading-base">
        {children}
      </div>
      <div className="desktop:large:basis-2/12 laptop:basis-1/12 mobile:tablet:basis-0" />
    </div>
  );
}
