import { useId } from 'react';
import { SelectOption } from 'domain/types';
import { ReactComponent as DropdownArrowIcon } from 'assets/icons/dropdown-arrow.svg';
import StyledLabel from './StyledLabel';
import StyledError from './StyledError';

export const toSelectable = ([value, label]: string[]): SelectOption => ({ value, label });

type Props = {
  label: string,
  name: string,
  options: SelectOption[];
  emptyOption?: boolean;
  disabled?: boolean;
  required?: boolean;
  register?: Object;
  error?: string;
  onChange?: Function;
  onBlur?: Function;
};

export default function Select({
  label, name, options, emptyOption = true, disabled = false, required = false, register = {},
  error, onChange, onBlur
}: Props) {
  const inputId = useId();

  const handleChange = () => {
    if (onChange) {
      onChange();
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <div className="h-[76px] w-full relative">
      <select
        id={inputId}
        name={name}
        disabled={disabled}
        placeholder={label}
        required={required}
        onChange={handleChange}
        onBlur={handleBlur}
        {...register}
        defaultValue=""
        className={`
          peer
          w-full h-[56px] transition-all appearance-none
          rounded-lg border-[1px] border-gray-300
          bg-white outline-none px-sm pb-xsm pt-base
          text-gray-600 caret-brand-90 placeholder-transparent
          focus:shadow-3
          disabled:text-gray-400 disabled:bg-gray-200 disabled:shadow-0
          dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 dark:focus:shadow-dark-3
          dark:disabled:border-gray-300 dark:disabled:bg-gray-700
          ${error ? 'border-error-100' : ''}
        `}
      >
        {emptyOption ? <option value="" disabled></option> : null}
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
      <StyledLabel
        id={inputId}
        label={label}
        error={error}
      />
      <div className="absolute top-[28px] right-sm pointer-events-none">
        <DropdownArrowIcon className="fill-gray-600 dark:text-gray-200" />
      </div>
      <StyledError error={error} />
    </div>
  );
}
