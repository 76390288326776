import { PopupTemplate, PopupTemplates } from 'domain/types';

/*
 * Expressions use ArcGIS Arcade expressions.
 * https://developers.arcgis.com/javascript/latest/arcade/
 */

const expressionDetectionStage: PopupTemplate = {
  title: '{expression/detection-date}',
  expressionInfos: [
    {
      name: 'detection-date',
      expression: `
        var date = Left($feature.Detection_Date, 10);    
        var prob = Round($feature.Probability, 1);
        var isNdvi = Find($feature.Supported_By, 'NdviChange', 0) > -1;
        var desc = When(prob == 0.5 || prob == 0.6, 'Early', prob == 0.7 && isNdvi, 'Visible', prob == 0.7, 'Early', prob == 0.8 && isNdvi, 'Late', prob == 0.8, 'Visible', prob == 0.9 && isNdvi, 'Late', prob == 0.9, 'Visible', 'n/a');
        return \`\${desc} Detection\nDetection date: \${date}\`;
      `
    }
  ]
};

export const barkBeetleTemplates: PopupTemplates = new Map([
  ['Auto_Stands', {
    title: '{Avdelning_benämning}',
  }],
  ['Property subdivision', {
    title: '{fastighet}',
  }],
  ['Property_subdivision', {
    title: '{Fastighetsbeteckning}',
  }],
  ['Proactive barkbeetle', expressionDetectionStage],
  ['Recurrent early season bark beetle', expressionDetectionStage],
  ['Barkbeetle_pred_Polygons', expressionDetectionStage],
  ['Previous years', expressionDetectionStage],
  ['Current season', expressionDetectionStage],
]);
