import React from 'react';

type Props = React.PropsWithChildren<{
  type?: 'button' | 'reset' | 'submit',
  state?: 'primary' | 'secondaryGray' | 'secondaryWhite' | 'transparent' | 'none',
  size?: 'large' | 'medium' | 'small' | 'none',
  disabled?: boolean,
  onClick?: Function
}>;

const commonStyle = `
  w-full font-bold uppercase transition-all box-border
  hover:shadow-3 active:shadow-0 disabled:shadow-0
  dark:hover:shadow-dark-3 dark:active:shadow-0 dark:disabled:shadow-0
`;

const stateStyle = new Map<string, string>([
  ['primary', `
    ${commonStyle}
    bg-brand-110 text-white fill-white
    hover:bg-brand-100
    active:text-white/80
    disabled:bg-brand-32 disabled:text-white/80
  `],
  ['secondaryGray', `
    ${commonStyle}
    bg-gray-200 text-gray-600 fill-gray-600
    hover:bg-white
    active:bg-gray-300 active:text-gray-600/80 active:fill-gray-600/80
    disabled:bg-gray-200 disabled:text-gray-400 disabled:fill-gray-400
    dark:bg-gray-700 dark:text-gray-200 dark:fill-gray-200
    dark:hover:bg-gray-600
    dark:active:bg-gray-700 dark:active:text-gray-200/80 dark:active:fill-gray-200/80
    dark:disabled:bg-gray-700/32 dark:disabled:text-gray-200 dark:disabled:fill-gray-200
  `],
  ['secondaryWhite', `
    ${commonStyle}
    bg-white text-gray-600 fill-gray-600
    active:bg-gray-200 active:text-gray-600/80 active:fill-gray-600/80
    disabled:bg-white disabled:text-gray-400 disabled:fill-gray-400
    dark:bg-gray-800 dark:text-gray-200 dark:fill-gray-200
    dark:hover:bg-gray-600 dark:hover:bg-gray-200
    dark:active:bg-gray-800 dark:active:text-200/80 dark:active:fill-200/80
    dark:disabled:bg-gray-800/32 dark:disabled:text-gray-200 dark:disabled:fill-gray-200
  `],
  ['transparent', `
    ${commonStyle}
    bg-transparent text-gray-600 fill-gray-600
    hover:bg-white
    active:bg-transparent active:text-gray-600/80 active:fill-gray-600/80
    disabled:bg-transparent disabled:text-gray-600 disabled:fill-gray-600
    dark:text-gray-200 dark:fill-gray-200
    dark:hover:bg-gray-600 dark:hover:text-gray-200 dark:hover:fill-gray-200
    dark:active:bg-transparent dark:active:text-gray-200/80 dark:active:fill-gray-200/80
    dark:disabled:bg-transparent dark:disabled:text-gray-200 dark:disabled:fill-gray-200
  `],
  ['none', `
    bg-transparent text-gray-600 fill-gray-600
    hover:bg-transparent
    dark:text-gray-200 dark:fill-gray-200
    dark:hover:bg-transparent dark:hover:text-gray-200 dark:hover:fill-gray-200
  `],
]);

const sizeStyle = new Map<string, string>([
  ['large', 'py-sm px-base rounded-lg text-button-lg leading-button-lg'],
  ['medium', 'py-[13px] px-sm rounded-md text-button-md leading-button-md'],
  ['small', 'py-[9px] px-sm rounded-sm text-button-sm leading-button-sm'],
  ['none', 'py-0 px-0'],
]);

export default function Button({
  children,
  type = 'button',
  state = 'primary',
  size = 'medium',
  disabled = false,
  onClick,
}: Props) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={handleClick}
      className={`${stateStyle.get(state)} ${sizeStyle.get(size)}`}
    >
      {children}
    </button>
  );
}
