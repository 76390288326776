import { defineMessages } from 'react-intl'

export default defineMessages({
  textTitle: {
    id: 'Info.text.title',
    defaultMessage: 'Information'
  },
  textTitleCookies: {
    id: 'Info.text.title_cookies',
    defaultMessage: 'Cookies'
  },
  textGeneralCookie: {
    id: 'Info.text.general_cookie',
    defaultMessage: 'A cookie is a small text file that a website stores on your computer or mobile device when you visit the site.'
  },
  textCookieBulletPointA: {
    id: 'Info.text.cookie_bullet_point_a',
    defaultMessage: 'First party cookies are cookies set by the website you’re visiting. Only that website can read them. In addition, a website might potentially use external services, which also set their own cookies, known as third-party cookies.'
  },
  textCookieBulletPointB: {
    id: 'Info.text.cookie_bullet_point_b',
    defaultMessage: 'Persistent cookies are cookies saved on your computer and that are not deleted automatically when you quit your browser, unlike a session cookie, which is deleted when you quit your browser.'
  },
  textAppCookies: {
    id: 'Info.text.app_cookies',
    defaultMessage: 'The Intellion website mostly use "first-party cookies". These are cookies set and controlled by Intellion, not by any external organisation. However, to view the maps you have to accept cookies from external organizations.'
  },
  textAppAuthCookie: {
    id: 'Info.text.app_auth_cookie',
    defaultMessage: 'The Intellion website uses one cookie called AccessToken which is an authentication cookie, used to provide a secure sign in process.'
  },
  textExternalCookies: {
    id: 'Info.text.externals_cookies',
    defaultMessage: 'To view this third-party content, you first have to accept their specific terms and conditions. This includes their cookie policies, which we have no control over.'
  },
  textArcGisCookies: {
    id: 'Info.text.arcgis_cookies',
    defaultMessage: 'Please read our map provider`s <a>cookie policy</a>.'
  },
});
