import { FormattedMessage } from 'react-intl';
import MainLayout from 'components/MainLayout';
import messages from './messages';

export default function Info() {
  return (
    <MainLayout>
      <h2 className="pb-base">
        <FormattedMessage {...messages.textTitle} />
      </h2>

      <h3 className="pb-sm">
        <FormattedMessage {...messages.textTitleCookies} />
      </h3>
      <p className="pb-sm">
        <FormattedMessage {...messages.textGeneralCookie} />
      </p>
      <ul className="list-disc pb-sm pl-md">
        <li>
          <FormattedMessage {...messages.textCookieBulletPointA} />
        </li>
        <li className="pt-xsm">
          <FormattedMessage {...messages.textCookieBulletPointB} />
        </li>
      </ul>
      <p className="pb-sm">
        <FormattedMessage {...messages.textAppCookies} />
      </p>
      <p className="pb-sm">
        <FormattedMessage {...messages.textExternalCookies} />
      </p>
      <p className="pb-sm">
        <FormattedMessage
          {...messages.textArcGisCookies}
          values={{
            a: chunks => (
              <a
                target="_blank"
                rel="noreferrer"
                href="https://trust.arcgis.com/en/privacy/privacy-tab-intro.htm"
              >
                {chunks}
              </a>
            )
          }}
        />
      </p>
      <p className="pb-sm">
        <FormattedMessage {...messages.textAppAuthCookie} />
      </p>
    </MainLayout>
  );
}
