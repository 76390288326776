import { QueryClient, QueryClientProvider } from 'react-query'
import AppMain from './components/App/AppMain';
import { ReactQueryDevtools } from 'react-query/devtools'

export default function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AppMain />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
