type Props = {
  size?: 'small' | 'medium' | 'large';
  inline?: boolean;
  text?: string;
  title?: string;
};

const getWrapperSize = (size: string): string => {
  if (size === 'small') {
    return 'w-spinner-sm h-spinner-sm';
  }
  if (size === 'large') {
    return 'w-spinner-lg h-spinner-lg';
  }
  return 'w-spinner-md h-spinner-md';
};

const getDotSize = (size: string): string => {
  if (size === 'small') {
    return 'w-dot-sm h-dot-sm';
  }
  if (size === 'large') {
    return 'w-dot-lg h-dot-lg';
  }
  return 'w-dot-md h-dot-md';
};

const pos: Map<number, { top: string, left: string }> = new Map([
  [0, { top: 'top-[12.5%]', left: 'left-[58.33%]' }],
  [1, { top: 'top-[41.67%]', left: 'left-[75%]' }],
  [2, { top: 'top-[70.83%]', left: 'left-[58.33%]' }],
  [3, { top: 'top-[70.83%]', left: 'left-[25%]' }],
  [4, { top: 'top-[41.67%]', left: 'left-[8.33%]' }],
  [5, { top: 'top-[12.5%]', left: 'left-[25%]' }]
]);

const animation: Map<number, string> = new Map([
  [0, `animate-[hqvspin_2s_linear_0s_infinite]`],
  [1, `animate-[hqvspin_2s_linear_0.17s_infinite]`],
  [2, `animate-[hqvspin_2s_linear_0.33s_infinite]`],
  [3, `animate-[hqvspin_2s_linear_0.5s_infinite]`],
  [4, `animate-[hqvspin_2s_linear_0.67s_infinite]`],
  [5, `animate-[hqvspin_2s_linear_0.83s_infinite]`]
]);

export default function Spinner({ size = 'medium', inline = false, text = '', title = '' }: Props) {
  const getTopPos = (index: number): string | undefined => pos.get(index)?.top;
  const getLeftPos = (index: number): string | undefined => pos.get(index)?.left;
  const getAnimation = (index: number): string | undefined => animation.get(index);

  const wrapperSize = getWrapperSize(size);
  const dotSize = getDotSize(size);
  const textClassName = inline ? 'p-xsm' : '';

  return (
    <div
      title={title}
      role="figure"
      className={`
        relative flex ${inline ? 'flex-row' : 'flex-col'}
        items-center justify-center w-full
      `}
    >
      <div
        className={`
          relative ${wrapperSize}
        `}
      >
        {[0, 1, 2, 3, 4, 5].map(i => (
          <div
            key={`dot-${i}`}
            className={`
              absolute ${getTopPos(i)} ${getLeftPos(i)} ${dotSize}
              rounded-[50%] bg-gray-300 ${getAnimation(i)}
            `}
          />
        ))}
      </div>
      {text ? (
        <div className={textClassName}>
          {text}
        </div>
      ) : null}
    </div>
  );
}
