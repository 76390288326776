import { NavLink, useResolvedPath, useMatch } from 'react-router-dom';

type Props = {
  path: string;
  label: string;
}

const classNames = 'no-underline pt-xsm transition-all';
const activeClassName = `${classNames} text-gray-600 dark:text-gray-200`;
const inactiveClassName = `${classNames} text-gray-500 dark:text-gray-100 group-hover:text-gray-600 dark:group-hover:text-gray-200`;
const shieldBaseClasses = `
  relative
  mt-auto bg-brand-100 h-[5px] w-full transition-all
  before:absolute before:bottom-[0px] before:left-[0px]
  before:content-[''] before:border-t-0 before:border-r-0 before:border-b-[5px] before:border-l-[8px]
  before:border-t-white before:border-r-white before:border-b-brand-100 before:border-l-white
  dark:before:border-t-gray-700 dark:before:border-r-gray-700 dark:before:border-l-gray-700
  after:absolute after:bottom-[0px] after:right-[0px]
  after:content-[''] after:border-t-[5px] after:border-r-0 after:border-b-0 after:border-l-[8px]
  after:border-t-white after:border-r-white after:border-b-white after:border-l-brand-100
  dark:after:border-t-gray-700 dark:after:border-r-gray-700 dark:after:border-b-gray-700
  group-hover:visible
`;

export default function MenuItem({ path, label }: Props) {
  const resolved = useResolvedPath(path);
  const match = useMatch({ path: resolved.pathname, end: true });
  const shieldClassName = `${!!match ? 'visible' : 'invisible'} ${shieldBaseClasses}`;

  return (
    <div className="relative flex flex-col h-full font-bold transition-all group">
      <NavLink
        to={path}
        title={label}
        className={({ isActive }) => isActive ? activeClassName : inactiveClassName}
      >
        {label}
      </NavLink>
      <div className={shieldClassName} role="figure" />
    </div>
  );
}
