import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { useArcGisDemoServer } from './queries';
import MapLoader from 'components/MapLoader/MapLoader';
import Spinner from 'components/Spinner';
import { barkBeetleTemplates } from './popupTemplates';

export default function Demo() {
  const arcGisServer = useArcGisDemoServer();

  if (arcGisServer.data) {
    return (
      <>
        <MapLoader
          arcGisServer={arcGisServer.data}
          popupTemplates={barkBeetleTemplates}
        />
        <div
          className="
            absolute top-0 left-[50%] translate-x-[-50%] mt-base
            bg-caution-100 uppercase font-bold text-gray-700 text-lg dark:bg-caution-132 dark:text-white
            rounded-sm px-sm py-xsm
          "
        >
          <FormattedMessage {...messages.textDemoBanner} />
        </div>
      </>
    );
  }

  return (
    <Spinner size="large" />
  );
}
