import { PropsWithChildren, useState } from 'react';

type Props = {
  label: string,
  expanded?: boolean;
};

export default function Accordion({
  label,
  expanded = false,
  children
}: PropsWithChildren<Props>) {

  const [isExpanded, setIsExpanded] = useState(expanded)

  return (
    <div className="w-full relative pb-sm">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="
          w-full h-xlg font-bold uppercase pl-md text-left
          bg-white text-gray-600 caret-brand-90
          disabled:text-gray-400 disabled:bg-gray-200
          dark:bg-gray-700 dark:text-gray-200
          dark:disabled:bg-gray-700"
        type="button"
      >
        {label}
      </button>
      <div className={`mt-xxsm mr-[0px] overflow-hidden transition-all duration-1000 ${ isExpanded ? 'h-[160px]' : 'h-[0px]'}`}>
        {children}
      </div>
    </div>
  );
}
