import { useState } from 'react';
import { useIntl } from 'react-intl';
import { createUser } from 'api/diam/diamApi';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import PublicLayout from 'components/PublicLayout';
import SignUpForm from './SignUpForm';
import SignUpSuccess from './SignUpSuccess';
import messages from './messages';

export default function SignUp() {
  const intl = useIntl();

  const [signUpState, setSignUpState] = useState<'init' | 'success' | 'error'>('init');

  const createDiamUser = useMutation(createUser, {
    onSuccess: () => {
      setSignUpState('success');
    },
    onError: (error: Error) => {
      const msg = error.message === 'user.already.exists' ? messages.errorUserExists : messages.errorGeneral;
      toast.error(intl.formatMessage(msg));
    }
  });

  return (
    <PublicLayout>
      <div className="px-lg flex flex-col basis-2 space-y-sm">
        {signUpState === 'init' ? <SignUpForm createUser={createDiamUser} /> : null}
        {signUpState === 'success' ? <SignUpSuccess /> : null}
      </div>
    </PublicLayout>
  );
}
