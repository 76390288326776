import { useId, useState } from 'react';
import { ReactComponent as ShowPasswordIcon } from 'assets/icons/show-password.svg';
import { ReactComponent as ShowPasswordDisabledIcon } from 'assets/icons/show-password-disabled.svg';
import StyledLabel from './StyledLabel';
import StyledError from './StyledError';

type Props = {
  label: string;
  name: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  register?: Object;
  pattern?: string;
  error?: string;
  onChange?: Function;
  onBlur?: Function;
};

export default function Input({
  label, name, type = 'text', disabled = false, required = false, register = {},
  pattern, error, onChange, onBlur
}: Props) {
  const inputId = useId();

  const [inputType, setInputType] = useState(type);

  const passwordIcon = inputType === 'password' ? (
    <ShowPasswordIcon className="w-[18px] h-[18px] fill-gray-600 dark:fill-gray-200" />
  ) : (
    <ShowPasswordDisabledIcon className="w-[18px] h-[18px] fill-gray-600 dark:fill-gray-200" />
  );

  const togglePassword = () => setInputType(prev => prev === 'password' ? 'text' : 'password');

  const handleChange = () => {
    if (onChange) {
      onChange();
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <div className="h-[80px] w-full relative">
      <input
        id={inputId}
        name={name}
        type={inputType}
        disabled={disabled}
        placeholder={label}
        required={required}
        pattern={pattern}
        onChange={handleChange}
        onBlur={handleBlur}
        {...register}
        className={`
          peer
          w-full h-[56px] transition-all
          rounded-lg border-[1px] border-gray-300
          bg-white outline-none px-sm pb-xsm pt-base
          text-gray-600 caret-brand-90 placeholder-transparent
          focus:shadow-3
          disabled:text-gray-400 disabled:bg-gray-200 disabled:shadow-0
          dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200 dark:focus:shadow-dark-3
          dark:disabled:border-gray-300 dark:disabled:bg-gray-700
          ${error ? 'border-error-100 dark:border-error-100' : ''}
        `}
      />
      <StyledLabel
        id={inputId}
        label={label}
        error={error}
      />
      {type === 'password' ? (
        <div
          className="absolute top-[22px] right-sm cursor-pointer"
          onClick={togglePassword}
        >
          {passwordIcon}
        </div>
      ) : null}
      <StyledError error={error} />
    </div>
  );
}
