import { defineMessages } from 'react-intl'

export default defineMessages({
  labelDemo: {
    id: 'Navbar.label.demo',
    defaultMessage: 'Demo'
  },
  labelBarkBeetle: {
    id: 'Navbar.label.barkbeetle',
    defaultMessage: 'Bark beetle'
  },
  labelSignOut: {
    id: 'Navbar.label.sign_out',
    defaultMessage: 'Sign out'
  },
  labelInfo: {
    id: 'Navbar.label.info',
    defaultMessage: 'Info'
  },
  labelProfile: {
    id: 'Navbar.label.profile',
    defaultMessage: 'Profile'
  },
  labelHelp: {
    id: 'Navbar.label.help',
    defaultMessage: 'Help'
  },
});
