export const getLayers = async (url: string, token: string) => {
  const response = await fetch(`${url}?f=json&token=${token}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const hydrateLayers = async (url: string, token: string, layerIds: string[]) => {
  if (layerIds?.length > 0) {
    return Promise.all((await Promise.all(
      layerIds.map(id => fetch(`${url}/${id}?f=json&token=${token}`))
      )).map((fullLayer: Response) => fullLayer.json()));
  }
  return [];
};