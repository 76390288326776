import { useQuery } from 'react-query';
import { getArcGisToken } from 'api/intellion/intellionApi';
import { getLayers, hydrateLayers } from 'api/esri/arcGisApi';
import { arcGisLayerKeys, arcGisLayersKeys, arcGisTokenKeys } from './queryKeyFactory';

type LayerProps = {
  server: string;
  token: string;
  layerIds?: string[];
};

export const useArcGisToken = () => useQuery(arcGisTokenKeys.all, getArcGisToken, {
  refetchOnWindowFocus: false,
  cacheTime: 60 * 60 * 1000, // 60 minutes
  staleTime: 60 * 60 * 1000, // 60 minutes
  select: (data) => {
    return data?.payload?.token
  }
});

export const useArcGisLayers = ({ server, token }: LayerProps) => useQuery<any>(
  arcGisLayersKeys.featureServer(server),
  () => getLayers(server, token), {
    refetchOnWindowFocus: false,
    select: data => data?.layers
  });

export const useHydratedArcGisLayers = ({
  server,
  token,
  layerIds = []
}: Required<LayerProps>) => useQuery(
  arcGisLayerKeys.layerId(layerIds),
  () => hydrateLayers(server, token, layerIds), {
    refetchOnWindowFocus: false,
    select: data => data?.map((d: any) => ({
      id: d.id,
      name: d.name,
      drawingInfo: d.drawingInfo
    }))
  });