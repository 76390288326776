import { useQuery } from 'react-query';
import { getUser } from 'api/intellion/intellionApi';
import { userKeys } from 'components/queryKeyFactory';

export const useArcGisServer = () => useQuery(userKeys.all, getUser, {
  refetchOnWindowFocus: false,
  select: (data) => {
    return data?.payload?.feature_url;
  }
});

export const useArcGisDemoServer = () => useQuery(userKeys.all, getUser, {
  refetchOnWindowFocus: false,
  cacheTime: 60 * 60 * 1000, // 60 minutes
  staleTime: 60 * 60 * 1000, // 60 minutes
  select: (data) => {
    return data?.payload?.demo_url;
  }
});
