import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export default function SignUpSuccess() {
  return (
    <div>
      <h2 className="pb-sm">
        <FormattedMessage {...messages.textTitleSignUpSuccess} />
      </h2>
      <p>
        <FormattedMessage
          {...messages.textSignUpSuccessInfo}
          values={{
            a: chunks => (
              <Link to="/signin">
                {chunks}
              </Link>
            )
          }}
        />
      </p>
    </div>
  );
}
