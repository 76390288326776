import React from 'react';
import { loadModules } from 'esri-loader';
import IMap from 'esri/Map';
import IIdentityManager from 'esri/identity/IdentityManager';
import IMapView from 'esri/views/MapView';
import IIntl from 'esri/intl';
import IBasemap from 'esri/Basemap';
import IBasemapToggle from 'esri/widgets/BasemapToggle';
import ILayerList from 'esri/widgets/LayerList';
import IFeatureLayer from 'esri/layers/FeatureLayer';
import { goToExtent } from './mapHelpers';
import { PopupTemplate, PopupTemplates } from 'domain/types';

type LoadMapArgs = {
  mapRef: React.RefObject<HTMLDivElement>;
  locale: string;
  server: string;
  token: string;
  layers: any;
  popupTemplates?: PopupTemplates;
};

type OrderedByProperties = {
  valueExpression: string,
  order: 'ascending' | 'descending'
}

type FeatureLayerProps = {
  url: string;
  title: string;
  listMode: 'hide' | 'show';
  minScale: number;
  editingEnabled: boolean;
  popupEnabled: boolean;
  popupTemplate?: PopupTemplate;
  orderBy?: OrderedByProperties[];
};

export const loadMap = async (args: LoadMapArgs) => {
  const options = {
    css: true
  };

  const [
    IdentityManager,
    intl,
    Map,
    MapView,
    Basemap,
    BaseMapToggle,
    LayerList,
    FeatureLayer,
    { default: Legend }
  ] = await loadModules<[
    typeof IIdentityManager,
    typeof IIntl,
    typeof IMap,
    typeof IMapView,
    typeof IBasemap,
    typeof IBasemapToggle,
    typeof ILayerList,
    typeof IFeatureLayer,
    any
  ]>([
    'esri/identity/IdentityManager',
    'esri/intl',
    'esri/Map',
    'esri/views/MapView',
    'esri/Basemap',
    'esri/widgets/BasemapToggle',
    'esri/widgets/LayerList',
    'esri/layers/FeatureLayer',
    'widgets/Legend',
  ], options);

  IdentityManager.registerToken({
    server: args.server,
    token: args.token,
  });

  const map = new Map({
    basemap: 'satellite',
  });

  const view = new MapView({
    map,
    center: [14.279923, 57.788276], // Center on Husqvarna AB
    zoom: 10,
    container: args.mapRef.current || undefined
  });

  const basemapToggle = new BaseMapToggle({
    view: view,
    nextBasemap: Basemap.fromId('topo-vector')
  });

  basemapToggle.on('toggle', function(event){
    if (event.current.id === 'topo-vector') {
      legend.lightBasemap = true
    }
    else {
      legend.lightBasemap = false
    }
  });

  const layerList = new LayerList({
    view: view,
  });

  const detectionLayerLabels = [
    'previous years',
    'current season'
  ]
  const featureLayers = args.layers
    .map((layer: any) => {
      const isDetectionLayer = detectionLayerLabels.includes(layer.name.toLowerCase());

      const layerData: FeatureLayerProps = {
        url: `${args.server}/${layer.id}`,
        title: layer.name,
        minScale: 20000000, // Possible to view all layers when zoomed out to see all of Swedish
        listMode: isDetectionLayer ? 'show' : 'hide',
        editingEnabled: isDetectionLayer ? true : false,
        popupEnabled: isDetectionLayer ? true : false,
        orderBy: isDetectionLayer ? [{
          valueExpression: "DefaultValue($feature.Probability, 0)",
          order: "descending"
        }] : [],
      };

      const template = args.popupTemplates?.get(layer.name);
      if (template) {
        layerData.popupTemplate = template;
      }

      return new FeatureLayer(layerData);
    });

  view.map.addMany(featureLayers);

  const legendValues = [
    'DeepLearning NdviChange,0.5',
    'DeepLearning NdviChange,0.7',
    'DeepLearning NdviChange,0.8',
  ];

  const legend = new Legend({
    rows: ['Previous years', 'Current season'],
    columns: ['Early Detections', 'Visible Detections', 'Late Detections'],
    values: legendValues,
    layers: args.layers,
    lightBasemap: false,
  });

  return view.when(() => {
    intl.setLocale(args.locale);

    view.ui.add(basemapToggle, {
      position: 'bottom-left'
    });
    view.ui.add(layerList, {
      position: 'top-right'
    });
    view.ui.add(legend, {
      position: 'bottom-right'
    });

    // Center map on layers
    goToExtent(view);

    return view;
  });
}
