import { IntlProvider } from 'react-intl';
import 'react-toastify/dist/ReactToastify.css';
import { Locale, messages } from 'domain/locales';
import { useUser } from 'components/queries';
import Toaster from 'components/Toaster';
import AppRouter from './AppRouter';

export default function AppMain() {
  const user = useUser();

  return (
    <IntlProvider
      locale={user.data?.payload?.language || Locale.English}
      messages={messages.get(user.data?.payload?.language || Locale.English)}>
      <AppRouter />
      <Toaster />
    </IntlProvider>
  );
}
