export const arcGisTokenKeys = {
  all: ['arcgisToken'] as const,
};

export const arcGisLayersKeys = {
  all: ['arcgisLayers'] as const,
  featureServer: (server: string) => [...arcGisLayerKeys.all, server] as const,
};

export const arcGisLayerKeys = {
  all: ['arcgisLayer'] as const,
  layerId: (ids: string[]) => [...arcGisLayerKeys.all, ...ids] as const,
};
