import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from 'components/ProtectedRoute';
import Landing from 'components/Landing';
import SignIn from 'components/SignIn';
import SignUp from 'components/SignUp';
import Demo from 'components/Analysis/Demo';
import BarkBeetle from 'components/Analysis/BarkBeetle';
import Info from 'components/Info';
import Profile from 'components/Profile';
import PageNotFound from 'components/PageNotFound';
import Help from 'components/Help';

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/demo" element={<Demo />} />
          <Route path="/barkbeetle" element={<BarkBeetle />} />
          <Route path="/help" element={<Help />} />
          <Route path="/info" element={<Info />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}
