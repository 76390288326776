import { defineMessages } from 'react-intl'

export default defineMessages({
  textTitle: {
    id: 'PropertyDialog.text.title',
    defaultMessage: 'Property'
  },
  textPropertyInfo: {
    id: 'PropertyDialog.text.property_info',
    defaultMessage: 'Enter one or more property names that you own and press the save button. The analysis will start and you will get a notification by e-mail when ready. Allow for up to four weeks for the analysis to finish. You can add or delete properties from the user profile page too.'
  },
  labelProperty: {
    id: 'PropertyDialog.label.property',
    defaultMessage: 'Property name'
  },
  errorRequired: {
    id: 'PropertyDialog.error.required',
    defaultMessage: '{input} is required'
  },
  errorRequest: {
    id: 'PropertyDialog.error.request',
    defaultMessage: 'Something went wrong when storing properties. Please contact the Intellion support.'
  },
  buttonAddProperty: {
    id: 'PropertyDialog.button.add_property',
    defaultMessage: 'Add another property'
  },
  buttonSave: {
    id: 'PropertyDialog.button.save',
    defaultMessage: 'Save'
  },
});
