/* eslint-disable no-undef */
const localConfig = {
  api: {
    get endpoint() {
      return process.env.REACT_APP_INTELLION_API_URL;
    }
  },
  diam: {
    get endpoint() {
      return process.env.REACT_APP_DIAM_API_URL;
    },
    get clientId() {
      return process.env.REACT_APP_DIAM_CLIENT_ID;
    }
  },
  agol: {
    sharing: {
      get endpoint() {
        return process.env.REACT_APP_AGOL_SHARING_URL;
      }
    }
  }
};

console.info(process.env.NODE_ENV === 'development' ? 'Using local config' : `Using config for ${process.env.NODE_ENV}`);

export default process.env.NODE_ENV === 'development' ? localConfig : intellionConfig;
