import { defineMessages } from 'react-intl'

export default defineMessages({
  title404: {
    id: 'PageNotFound.title.404',
    defaultMessage: 'Lost in the woods'
  },
  text404: {
    id: 'PageNotFound.text.404',
    defaultMessage: 'The page you requested could not be found.'
  },
});
