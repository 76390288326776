import { PropsWithChildren } from 'react';

type Props = {
  onChange?: Function;
  onBlur?: Function;
};

export default function ListItem({
  children
}: PropsWithChildren<Props>) {

  return (
    <div className="
      flex flex-row justify-between
      w-full h-lg p-xsm pl-sm
      bg-white text-gray-600 fill-gray-600 caret-brand-90
      disabled:text-gray-400 disabled:fill-gray-400 disabled:bg-gray-200
      dark:bg-gray-700 dark:text-gray-200 dark:fill-gray-200
      dark:disabled:bg-gray-700h-[76px] relative
    ">
      {children}
    </div>
  );
}
