import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import qs from 'qs';
import AppConfig from 'appConfig';
import Button from 'components/form/Button';
import messages from './messages';
import PublicLayout from 'components/PublicLayout';
import { useEffect } from 'react';

const generateRandomString = (length = 10) => Math.random().toString(20).substring(2, length);

export default function SignIn() {
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const isStateValid = localStorage.getItem('state') === searchParams.state;
    if (searchParams.state && isStateValid) {
      navigate('/barkbeetle');
    }
  }, []);

  const handleSignIn = () => {
    const state = generateRandomString();
    localStorage.setItem('state', state);
    window.location.href = `${AppConfig.diam.endpoint}/oauth2/authorize?client_id=${AppConfig.diam.clientId}&redirect_uri=${AppConfig.api.endpoint}/api/0.1/user/session&state=${state}`;
  };

  return (
    <PublicLayout>
      <div className="px-lg flex flex-col basis-2 space-y-sm">
        <h2><FormattedMessage {...messages.textTitle} /></h2>
        <p><FormattedMessage {...messages.textCaption} /></p>
        <div className="w-full">
          <Button
            size="large"
            onClick={handleSignIn}
          >
            <FormattedMessage {...messages.buttonSignIn} />
          </Button>
        </div>
        <div>
          <p>
            <FormattedMessage {...messages.textNoAccount} /> <Link to="/signup"><FormattedMessage {...messages.linkSignUp} /></Link>
          </p>
          <p>
            <FormattedMessage
              {...messages.textNeedHelp}
              values={{
                a: () => (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:support-intellion@husqvarnagroup.com"
                  >
                    support-intellion@husqvarnagroup.com
                  </a>
                )
              }}
            />
          </p>
        </div>
      </div>
    </PublicLayout>
  );
}
