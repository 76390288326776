type Props = {
  error?: string;
};

export default function StyledError({ error }: Props) {
  return (
    <div className="h-[24px] text-error-100 font-bold text-caption leading-caption pt-xxsm pl-sm">
      {error}
    </div>
  );
}
