import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { useArcGisServer } from './queries';
import MapLoader from 'components/MapLoader/MapLoader';
import Spinner from 'components/Spinner';
import { barkBeetleTemplates } from './popupTemplates';
import './analysis.css';

export default function BarkBeetle() {
  const arcGisServer = useArcGisServer();

  if (arcGisServer.isSuccess && arcGisServer.data) {
    return (
      <MapLoader
        arcGisServer={arcGisServer.data}
        popupTemplates={barkBeetleTemplates}
      />
    );
  }

  if (arcGisServer.isSuccess && !arcGisServer.data) {
    return (
      <div className="w-full h-full flex justify-end p-base analysis-background">
        <div className="w-fit h-fit max-w-[500px] px-base py-sm rounded-sm bg-gray-700/50 text-lg text-white">
          <h3>
            <FormattedMessage {...messages.textAnalysisNotReadyTitle} />
          </h3>
          <p className="pt-sm">
            <FormattedMessage {...messages.textAnalysisNotReadyInfo} />
          </p>
        </div>
      </div>
    );
  }

  return (
    <Spinner size="large" />
  );
}
