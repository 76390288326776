import AppConfig from 'appConfig';
import { UserAttributes, CreateData } from 'domain/types';

const BASE_URL = AppConfig.diam.endpoint;

type Groups = {
  groups: string[];
};

type CreateUser = {
  data: {
    type: 'users';
    attributes: UserAttributes & Groups;
  };
  meta: {
    forward: {
      uri: string;
      text: string;
    };
  };
};

export const getLanguages = async () => {
  const response = await fetch(`${BASE_URL}/languages`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const createUser = async (data: CreateData) => {
  const user: CreateUser = {
    data: {
      type: 'users',
      attributes: {
        ...data['formData'],
        country: 'SE',
        groups: ['intellion-platform'],
      },
    },
    meta: {
      forward: {
        uri: window.location.origin,
        text: data['metaText'],
      },
    },
  };

  const response = await fetch(`${BASE_URL}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(user)
  });

  if (!response.ok) {
    const { errors } = await response.json();
    const userExists = errors.find((e: { code: string; }) => e.code === 'user.already.exists');
    console.log(errors, userExists?.code);
    const errorCode = userExists ? 'user.already.exists' : 'general.error';
    throw new Error(errorCode);
  }

  return response.json();
};
