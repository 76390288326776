export const goToExtent = async (view: any) => {
  Promise.all(view?.map?.layers?.items?.map((item: any) => {
    return item.queryExtent().then((res: any) => Promise.resolve(res));
  }))
  .then(values => {
    if (values) {
      const extents = values
        .filter(v => v.extent)
        .map(v => v.extent);

      if (extents?.length > 0) {
        const extent = extents.reduce((a, b) => a.union(b));
        view.goTo(extent);
      }
    }
  });
};
