import { FormattedMessage } from 'react-intl';
import messages from './messages';
import './pageNotFound.css';

export default function PageNotFound() {
  return (
    <div className="w-full h-full flex items-center justify-center page-not-found-background text-white">
      <div>
        <h1 className="pb-xsm">
          <FormattedMessage {...messages.title404} />
        </h1>
        <div className="border border-brand-100 w-[20%]" />
        <p className="pt-xsm text-lg">
          <FormattedMessage {...messages.text404} />
        </p>
      </div>
    </div>
  );
}
