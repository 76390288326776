import { ToastContainer } from 'react-toastify';

const contextClass = {
  success: 'bg-white text-gray-600 dark:bg-gray-700 dark:text-white',
  error: 'bg-white text-gray-600 dark:bg-gray-700 dark:text-white',
  info: 'bg-white text-gray-600 dark:bg-gray-700 dark:text-white',
  warning: 'bg-white text-gray-600 dark:bg-gray-700 dark:text-white',
  default: 'bg-white text-gray-600 dark:bg-gray-700 dark:text-white',
  dark: 'bg-gray-700 text-white',
};

export default function Toaster() {
  return (
    <ToastContainer
      toastClassName={(className) => contextClass[className?.type || 'default'] +
        ' relative flex p-sm mb-base min-h-10 rounded-md justify-between overflow-hidden shadow-1'
      }
      bodyClassName={() => 'flex flex-row block'}
      position="top-right"
      autoClose={5000}
      closeOnClick={true}
    />
  );
}
