import { useEffect, useState } from 'react';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useAddProperty, useProperties } from 'api/intellion/queries';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-outlined.svg';
import Button from 'components/form/Button';
import Input from 'components/form/Input';
import messages from './messages';

type Form = {
  properties: {
    value: string;
  }[];
};

export default function PropertyDialog() {
  const intl = useIntl();
  const { data: properties } = useProperties();

  const [isOpen, setIsOpen] = useState(false);

  const save = useAddProperty({
    onSuccess: () => {
      setIsOpen(false);
    },
    onError: () => {
      toast.error(intl.formatMessage(messages.errorRequest));
    }
  });

  useEffect(() => {
    if (properties?.payload?.length === 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [properties]);

  const { register, handleSubmit, control, formState: { errors } } = useForm<Form>({
    defaultValues: {
      properties: [{ value: '' }]
    }
  });

  const { fields, append, remove } = useFieldArray({
    name: 'properties',
    control
  });

  const handleAdd = () => {
    append({ value: '' });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const onSubmit: SubmitHandler<Form> = (formData: Form) => {
    save.mutate(formData.properties.map(p => p.value));
  };

  if (isOpen) {
    return (
      <div className="absolute top-0 left-0 w-full h-full bg-gray-700/40">
        <div
          className="
            absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]
            bg-white rounded-lg shadow-3 p-md desktop:w-1/3 laptop:w-2/5 tablet:w-5/6
            dark:bg-gray-800 dark:shadow-dark-3 dark:text-white transition-all
          "
        >
          <h3 className="pb-base">
            <FormattedMessage {...messages.textTitle} />
          </h3>
          <p className="pb-base">
            <FormattedMessage {...messages.textPropertyInfo} />
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field, index) => (
              <div key={field.id} className="w-full flex flex-row">
                <Input
                  name={field.id}
                  label={intl.formatMessage(messages.labelProperty)}
                  error={errors?.properties?.[index]?.value?.message}
                  register={register(`properties.${index}.value`, {
                    required: intl.formatMessage(messages.errorRequired, { input: intl.formatMessage(messages.labelProperty) })
                  })}
                />
                {index > 0 ? (
                  <div className="w-fit pt-[5px] pl-xsm">
                    <Button
                      state="secondaryGray"
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteIcon className="w-[20px] h-[20px]" />
                    </Button>
                  </div>
                ) : null}
              </div>
            ))}
            <div className="w-full pb-base flex justify-end">
              <div className="w-fit">
                <Button
                  state="secondaryGray"
                  onClick={handleAdd}
                >
                  <FormattedMessage {...messages.buttonAddProperty} />
                </Button>
              </div>
            </div>
            <div>
              <Button type="submit">
                <FormattedMessage {...messages.buttonSave} />
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return null;
}
