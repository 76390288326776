type Props = {
  label: string;
  id: string;
  error?: string;
};

export default function StyledLabel({ label, id, error }: Props) {
  return (
    <label
      htmlFor={id}
      className={`
        absolute top-xsm left-sm transition-all
        text-gray-500 text-label leading-label cursor-text
        peer-placeholder-shown:text-base peer-placeholder-shown:leading-base peer-placeholder-shown:top-sm
        peer-focus:text-label peer-focus:leading-label peer-focus:top-xsm
        peer-disabled:text-gray-400
        dark:text-gray-400
        ${error ? 'text-error-100 dark:text-error-100' : ''}
      `}
    >
      {label}
    </label>
  );
}
