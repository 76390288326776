import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { ReactComponent as GroupLogo } from 'assets/images/logo-hqvgroup.svg';

export default function AccountInfo() {
  return (
    <div className="py-base px-lg flex flex-col items-center">
      <div className="pb-xxsm">
        <GroupLogo className="fill-gray-400" />
      </div>
      <div className="text-gray-500 text-center">
        <FormattedMessage {...messages.textAccountInfo} />
      </div>
    </div>
  );
}
