import React from 'react';
import Navbar from './Navbar';
import PropertyDialog from 'components/PropertyDialog';

type Props = React.PropsWithChildren<{}>;

export default function ProtectedLayout({ children }: Props) {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-initial w-full">
        <Navbar />
      </div>
      <div className="flex flex-auto" role="main">
        {children}
      </div>
      <PropertyDialog />
    </div>
  );
}
