import AppInfo from 'components/AppInfo';
import AccountInfo from 'components/AccountInfo';

type Props = React.PropsWithChildren<{}>;

export default function PublicLayout({ children }: Props) {
  return (
    <div className="h-full w-full flex flex-row">
      {/* Left pane */}
      <div className="flex w-3/5">
        <AppInfo />
      </div>

      {/* Right pane */}
      <div className="h-full flex flex-col justify-between pt-xlg w-2/5">

        {children}

        <div className="flex self-center basis-1">
          <AccountInfo />
        </div>
      </div>
    </div>
  );
}
