import { PopupTemplates } from 'domain/types';
import BaseMap from './BaseMap';
import Spinner from 'components/Spinner';
import { useArcGisToken } from 'api/esri/queries';

type Props = {
  arcGisServer: string;
  popupTemplates: PopupTemplates;
};

export default function MapLoader({ arcGisServer, popupTemplates }: Props) {
  const arcGisToken = useArcGisToken();

  if (arcGisToken.data) {
    return (
      <BaseMap
        server={arcGisServer}
        token={arcGisToken.data}
        popupTemplates={popupTemplates}
      />
    );
  }

  return (
    <Spinner size="large" />
  );
}
