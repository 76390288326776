import messagesEn from 'assets/lang/en.json';
import messagesSv from 'assets/lang/sv.json';

export enum Locale {
  English = 'en',
  Swedish = 'sv',
}

export type Messages = typeof messagesSv;

export const messages = new Map([
  [Locale.Swedish, messagesSv],
  [Locale.English, messagesEn],
]);
